.home-page {
  font-family: Arial, sans-serif;
  font-size: 1.1rem;
  line-height: 1.2;
  color: #666;
  overflow-x: hidden;
}
@media (min-width: 768px) {
  .home-page {
    font-size: 1.6rem;
  }
}
.home-page .wrapper {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 25px;
}
@media (min-width: 768px) {
  .home-page .wrapper {
    padding: 0 40px;
  }
}
@media (min-width: 1200px) {
  .home-page .wrapper {
    padding: 0 50px;
  }
}
.home-page header h1 {
  font-family: "Arial Black", sans-serif;
  color: #fff;
  font-size: 3.166rem;
  line-height: 1.2;
}
.home-page .d-xl-none h1 {
  font-family: "Arial Black", sans-serif;
  color: #20558a;
  font-size: 1.3rem;
  line-height: 1.2;
}
.home-page h2 {
  font-family: "Arial Black", sans-serif;
  font-size: 1.8rem;
  line-height: 1.2;
  padding-bottom: 58px;
  background-image: url("/images/rebrand/dottedbg.png");
  background-repeat: repeat-x;
  background-position: bottom left;
}
.home-page h2 .h2-offscreen {
  margin-right: -2350px;
}
.home-page h3 {
  font-family: "Arial Black", sans-serif;
  font-size: 1.68rem;
  line-height: 1.1;
}
.home-page .heavy-text {
  font-family: "Arial Black", sans-serif;
}
.home-page #userNav {
  background-color: #130e10;
  color: #f2f2f2;
  font-size: 0.9rem;
}
.home-page #userNav a {
  color: white !important;
}
.home-page header {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
  color: #fff;
  background-image: url("/images/rebrand/banner_sm_no-tagline.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top -2px;
  position: relative;
  padding-bottom: 70.1%;
  height: 0;
}
.home-page header .navbar .navbar-collapse {
  position: absolute;
  top: 40px;
  right: calc(15px + 2%);
  padding: 0;
  margin-left: -15px;
  margin-right: -15px;
  background-color: rgba(74, 98, 0, 0.9);
  z-index: 2;
  width: 40%;
}
.home-page header .navbar-dark .navbar-nav .nav-item {
  padding: 0.25rem 0.5rem;
}
.home-page header .navbar-dark .navbar-nav .nav-item + .nav-item {
  border-top: 1px solid rgba(108, 138, 51, 0.5647058824);
}
.home-page header .navbar-dark .navbar-nav .nav-item .nav-link {
  font-size: 1rem;
}
.home-page .header-circles {
  position: absolute;
  pointer-events: none;
  top: 40px;
  right: 15px;
  width: 38%;
  font-size: 2.083125rem;
}
@media (min-width: 440px) {
  .home-page .header-circles {
    top: 70px;
    width: 42%;
  }
  .home-page header .navbar .navbar-collapse {
    top: 56px;
    max-width: 200px;
  }
}
@media (min-width: 1200px) {
  .home-page {
    font-size: 1.2rem;
  }
  .home-page .font-weight-bolder {
    font-size: 1.4rem;
  }
  .home-page header {
    background-image: url("/images/rebrand/banner_no-tagline.svg");
    padding-bottom: 50.5%;
  }
  .home-page header h1 {
    font-size: 1.7rem;
  }
}
@media (min-width: 1200px) and (min-width: 1300px) {
  .home-page header h1 {
    font-size: 2.166rem;
  }
}
@media (min-width: 1200px) {
  .home-page .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
  }
}
@media (min-width: 1200px) {
  .home-page header .navbar .navbar-collapse {
    position: static;
    margin-left: 0;
    margin-right: 0;
    padding: 0 3vw 0 0;
    background-color: transparent;
    height: 120px;
    max-width: none;
  }
}
@media (min-width: 1200px) {
  .home-page header .navbar-dark .navbar-nav .nav-item + .nav-item {
    border-top: none;
  }
}
@media (min-width: 1200px) {
  .home-page header .navbar-dark .navbar-nav .nav-item:not(:first-child) {
    position: relative;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .home-page header .navbar-dark .navbar-nav .nav-item + .nav-item:not(:last-child):before {
    content: "|";
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 1.1rem;
  }
}
@media (min-width: 1200px) {
  .home-page header .navbar-dark .navbar-nav .nav-item .nav-link {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1.5rem;
  }
}
@media (min-width: 1200px) and (min-width: 1300px) {
  .home-page header .navbar-dark .navbar-nav .nav-item .nav-link {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 1200px) {
  .home-page .header-circles {
    position: absolute;
    pointer-events: none;
    top: 160px;
    right: 0;
    width: 25%;
  }
  .home-page .header-circles img {
    padding-right: 50px;
  }
  .home-page .header-circles .circle-text {
    font-family: "Arial Black", sans-serif;
    color: #fff;
    position: absolute;
    top: 75%;
    left: 17%;
    width: 45%;
    font-size: 1.2rem;
    z-index: 2;
  }
}
@media (min-width: 1200px) and (min-width: 1440px) {
  .home-page .header-circles .circle-text {
    left: 17%;
    width: 48%;
    font-size: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .home-page h2 {
    font-size: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .home-page h3 {
    font-size: 1.25rem;
  }
}
@media (min-width: 1650px) {
  .home-page {
    font-size: 1.4rem;
  }
  .home-page header h1 {
    font-size: 2.8rem;
  }
  .home-page h2 {
    font-size: 1.95rem;
  }
  .home-page h3 {
    font-size: 1.5rem;
  }
  .home-page .header-circles {
    width: 33.33333%;
    top: 210px;
  }
  .home-page .header-circles .circle-text {
    font-size: 1.95rem;
    top: 77%;
    left: 18%;
    width: 51.5%;
  }
  .home-page header .navbar-dark .navbar-nav .nav-item .nav-link, .home-page header .navbar-dark .navbar-nav .nav-item + .nav-item:not(:last-child):before {
    font-size: 1.52rem;
  }
}
@media (min-width: 1790px) {
  .home-page {
    font-size: 1.6rem;
  }
  .home-page .font-weight-bolder {
    font-size: 2.083125rem;
  }
  .home-page header h1 {
    font-size: 3.166rem;
  }
  .home-page h2 {
    font-size: 2.35rem;
  }
  .home-page h3 {
    font-size: 1.68rem;
  }
}
@media (min-width: 2100px) {
  .home-page .header-circles {
    max-width: 590px;
  }
  .home-page .header-circles .circle-text {
    max-width: 288px;
    left: 112px;
  }
}
.home-page header .navbar {
  padding: 0;
}
.home-page header .navbar-dark .navbar-toggler {
  color: #fff;
  margin-left: auto;
  margin-right: 2%;
  margin-top: 10px;
  text-transform: uppercase;
  border-color: transparent;
  font-size: 1rem;
  background-color: rgba(74, 98, 0, 0.9);
}
@media (min-width: 440px) {
  .home-page header .navbar-dark .navbar-toggler {
    font-size: 1.25rem;
    margin-top: 20px;
  }
}
.home-page header .navbar-dark .navbar-toggler[aria-expanded=false] .close-text {
  display: none;
}
.home-page header .navbar-dark .navbar-toggler[aria-expanded=false] .menu-text {
  display: inline-block;
}
.home-page header .navbar-dark .navbar-toggler[aria-expanded=true] .close-text {
  display: inline-block;
}
.home-page header .navbar-dark .navbar-toggler[aria-expanded=true] .menu-text {
  display: none;
}
.home-page header .navbar-dark .navbar-nav .nav-link {
  color: #fff;
  text-decoration: none;
}
.home-page header .navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}
.home-page a {
  color: #20558a;
  text-decoration: underline;
}
.home-page a:active, .home-page a:hover, .home-page a:focus {
  color: #20558a;
  text-decoration: underline;
}
.home-page .btn-orange {
  background-color: #e57200;
  border-color: #e57200;
  color: #fff;
  padding: 6px 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.15rem;
}
@media (min-width: 440px) {
  .home-page .btn-orange {
    font-size: 1.47rem;
  }
}
@media (min-width: 1200px) {
  .home-page .btn-orange {
    font-size: 1.15rem;
  }
}
@media (min-width: 1650px) {
  .home-page .btn-orange {
    font-size: 1.47rem;
  }
}
.home-page .btn-orange:hover, .home-page .btn-orange:focus {
  background-color: #b25900;
  border-color: #b25900;
  color: #fff;
  text-decoration: none;
}
.home-page .btn-green {
  background-color: #719500;
  border-color: #719500;
  color: #fff;
  padding: 6px 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.15rem;
}
@media (min-width: 440px) {
  .home-page .btn-green {
    font-size: 1.47rem;
  }
}
@media (min-width: 1200px) {
  .home-page .btn-green {
    font-size: 1.15rem;
  }
}
@media (min-width: 1650px) {
  .home-page .btn-green {
    font-size: 1.47rem;
  }
}
.home-page .btn-green:hover, .home-page .btn-green:focus {
  background-color: #4a6200;
  border-color: #4a6200;
  color: #fff;
  text-decoration: none;
}
.home-page .people-circles {
  overflow: hidden;
  padding: 25px 0 15px;
}
.home-page .people-circles .top-right {
  position: absolute;
  top: 0;
  right: -25px;
}
.home-page .people-circles .top-left {
  position: absolute;
  top: 10px;
  left: -25px;
}
.home-page .people-circles .low-left {
  position: absolute;
  top: 0;
  left: -45px;
}
@media (min-width: 768px) {
  .home-page .people-circles .low-left {
    top: auto;
    bottom: 0;
    left: 55px;
  }
}
.home-page #about .top-left {
  margin-top: -30px;
}
.home-page #about .bottom-right {
  position: absolute;
  right: 0px;
  bottom: calc(-3rem - 40px);
  padding-left: 20px;
}
.home-page #about .orange-circle {
  width: 50vw;
  height: 50vw;
  max-width: 400px;
  max-height: 400px;
  font-family: "Arial Black", sans-serif;
  padding: 20px;
  font-size: 1.3rem;
}
@media (min-width: 440px) {
  .home-page #about .orange-circle {
    font-size: 2rem;
  }
}
@media (min-width: 630px) {
  .home-page #about .orange-circle {
    font-size: 2.5rem;
  }
}
.home-page #eligibility {
  background-color: #20558a;
  color: #fff;
}
.home-page #eligibility a, .home-page #eligibility a:hover, .home-page #eligibility a:focus {
  color: #fff;
  text-decoration: underline;
}
.home-page #eligibility .mobile-circle {
  position: absolute;
  width: 28%;
  top: -45px;
  right: 15px;
}
@media (min-width: 390px) {
  .home-page #eligibility .mobile-circle {
    width: 26%;
    top: -50px;
  }
}
@media (min-width: 440px) {
  .home-page #eligibility .mobile-circle {
    width: 18%;
    top: -90px;
  }
}
@media (min-width: 992px) {
  .home-page #eligibility .mobile-circle {
    top: -150px;
  }
}
.home-page .join-header {
  color: #fff;
  text-align: center;
  padding: 2.5rem 0;
  background-image: url("/images/rebrand/h2bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 992px) {
  .home-page .join-header {
    padding: 0.5rem 0;
  }
}
@media (min-width: 1200px) {
  .home-page .bottom-left {
    position: absolute;
    left: -20px;
    bottom: -40%;
  }
}
.home-page .contain-img {
  -o-object-fit: contain;
     object-fit: contain;
}
.home-page .overflow-img {
  max-width: none;
  width: 100%;
}
.home-page strong {
  font-weight: 600;
}
.home-page blockquote {
  background-color: #20558a;
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  margin: 2rem 0;
  padding: 34px 25px;
  border-radius: 5px;
  -webkit-box-shadow: 0 10px 40px -10px rgba(0, 64, 128, 0.2);
          box-shadow: 0 10px 40px -10px rgba(0, 64, 128, 0.2);
}
.home-page .timeline {
  margin-top: 40px;
  margin-bottom: 150px;
}
@media (min-width: 768px) {
  .home-page .timeline {
    margin-bottom: 175px;
  }
}
.home-page .timeline .center-line {
  position: absolute;
  height: 100%;
  width: 6px;
  background: #e57200;
  left: 50%;
  top: 20px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.home-page .timeline .odd-row, .home-page .timeline .even-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.home-page .timeline .odd-row {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.home-page .timeline .odd-row .details {
  background-image: url("/images/rebrand/stepsbg_odd.png");
}
.home-page .timeline .even-row {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.home-page .timeline .even-row .details {
  background-image: url("/images/rebrand/stepsbg_even.png");
}
.home-page .timeline .details {
  color: #fff;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  width: calc(50% - 63px);
  padding: 20px 40px 20px 60px;
  position: relative;
  background-repeat: repeat-y;
  background-size: 100%;
}
.home-page .timeline .details p {
  margin-bottom: 0;
  line-height: 1.1;
}
.home-page .timeline .details:before {
  position: absolute;
  content: "";
  height: 45px;
  width: 45px;
  top: 28px;
  z-index: -1;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background: #20558a;
}
.home-page .timeline .odd-row .details:before {
  right: -7px;
}
.home-page .timeline .even-row .details:before {
  left: -7px;
}
.home-page .timeline .details h3 {
  font-family: "Arial Black", sans-serif;
  color: #fff;
}
.home-page .timeline .details .icon, .home-page .timeline .center-line .icon {
  position: absolute;
  height: 92px;
  width: 92px;
  text-align: center;
  line-height: 92px;
  background-color: #e57200;
  border-radius: 50%;
}
.home-page .timeline .details img {
  max-width: 100%;
  width: auto;
  height: 90%;
}
.home-page .timeline .center-line .icon {
  bottom: -92px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.home-page .timeline .odd-row .details .icon {
  top: 10px;
  right: -108px;
}
.home-page .timeline .even-row .details .icon {
  top: 10px;
  left: -108px;
}
@media (max-width: 790px) {
  .home-page .timeline .center-line {
    z-index: -1;
  }
  .home-page .timeline .odd-row, .home-page .timeline .even-row {
    margin: 30px 0 120px;
  }
  .home-page .timeline .odd-row .details, .home-page .timeline .even-row .details {
    width: 100%;
    padding: 20px 40px;
  }
  .home-page .timeline .odd-row .details p, .home-page .timeline .even-row .details p {
    font-size: 1.5rem;
  }
  .home-page .timeline .odd-row .details {
    background-image: url("/images/rebrand/stepsbg_even.png");
  }
  .home-page .timeline .odd-row .details:before {
    right: calc(50% - 23px);
    top: calc(100% - 36px);
  }
  .home-page .timeline .even-row .details:before {
    left: calc(50% - 22px);
    top: calc(100% - 36px);
  }
  .home-page .timeline .odd-row .details .icon, .home-page .timeline .even-row .details .icon {
    left: calc(50% - 46px);
    top: -75px;
  }
  .home-page .timeline .even-row:last-child {
    margin-bottom: 20px;
  }
  .home-page .timeline .even-row:last-child .details:before {
    display: none;
  }
}
.home-page #join .nph-green-20 {
  margin-left: -15px;
  margin-right: -15px;
}
.home-page #join .nph-green-20 > .row {
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 992px) {
  .home-page #join .nph-green-20 > .row {
    padding-left: 55px;
    padding-right: 55px;
  }
}
@media (min-width: 1200px) {
  .home-page #join .nph-green-20 {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 70px;
  }
  .home-page #join .nph-green-20 > .row {
    padding-left: 0;
    padding-right: 0;
  }
}
.home-page #join .contact-row {
  margin-top: 2.8rem;
  overflow: hidden;
}
.home-page #join .contact-row .large-text {
  font-size: 16pt;
  line-height: 22pt;
}
@media (min-width: 992px) {
  .home-page #join .contact-row .large-text {
    font-size: 18pt;
    line-height: 25pt;
  }
}
.home-page #join .contact-row > .col-12:first-child {
  color: #fff;
  padding: 25px;
}
@media (min-width: 992px) {
  .home-page #join .contact-row > .col-12:first-child {
    padding: 60px;
  }
}
@media (min-width: 1200px) {
  .home-page #join .contact-row > .col-12:first-child {
    padding: 26px;
  }
}
@media (min-width: 1650px) {
  .home-page #join .contact-row > .col-12:first-child {
    padding: 60px;
  }
}
.home-page #join .contact-row > .col-12:last-child > div {
  background-color: #fff;
  padding: 25px;
}
@media (min-width: 1200px) {
  .home-page #join .contact-row > .col-12:last-child > div {
    -webkit-box-shadow: 0 10px 40px -10px rgba(0, 64, 128, 0.2);
            box-shadow: 0 10px 40px -10px rgba(0, 64, 128, 0.2);
  }
}
.home-page #join .contact-info {
  font-size: 1.33rem;
}
.home-page .accordion .card-header, .home-page .accordion .btn-link, .home-page .accordion .card-body {
  background-color: #20558a;
  color: #fff;
}
.home-page .accordion .card-header button {
  padding-right: 3rem;
  position: relative;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.3;
}
@media (min-width: 768px) {
  .home-page .accordion .card-header button {
    font-size: 1.52rem;
  }
}
@media (min-width: 1200px) {
  .home-page .accordion .card-header button {
    font-size: 1.25rem;
  }
}
@media (min-width: 1650px) {
  .home-page .accordion .card-header button {
    font-size: 1.52rem;
  }
}
.home-page .accordion .card-header button:after {
  position: absolute;
  content: "+";
  right: 1rem;
  top: 0;
  line-height: 0.9;
  display: inline-block;
  font-size: 2rem;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
@media (min-width: 768px) {
  .home-page .accordion .card-header button:after {
    line-height: 1.3;
  }
}
.home-page .accordion .card-header button[aria-expanded=true]:after {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}
.home-page .accordion > .card:not(:first-of-type) {
  border-radius: 0.25rem;
}
.home-page .accordion > .card:not(:last-of-type) {
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}
.home-page .accordion .card-body .col-12 > div {
  background-color: #fcf7ff;
  color: #383838;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  line-height: 1;
  padding: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.home-page .accordion .card-body .col-12 {
  margin-bottom: 10px;
}
.home-page .accordion .card-body .col-12:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .home-page .accordion .card-body .col-12 {
    margin-bottom: 0;
  }
}
.home-page .accordion .card-body .col-12 > div h3 {
  font-family: Arial, sans-serif;
  font-size: 0.9rem;
  line-height: 1.2;
  font-weight: 700;
}
@media (min-width: 440px) {
  .home-page .accordion .card-body .col-12 > div h3 {
    font-size: 1.1rem;
  }
}
@media (min-width: 1200px) {
  .home-page .accordion .card-body .col-12 > div h3 {
    font-size: 0.9rem;
  }
}
@media (min-width: 1650px) {
  .home-page .accordion .card-body .col-12 > div h3 {
    font-size: 1.32rem;
  }
}
.home-page .accordion .card-body .col-12 > div p {
  margin-bottom: 0;
  font-size: 0.9rem;
  line-height: 1.2;
}
@media (min-width: 440px) {
  .home-page .accordion .card-body .col-12 > div p {
    font-size: 1.1rem;
  }
}
@media (min-width: 1200px) {
  .home-page .accordion .card-body .col-12 > div p {
    font-size: 0.9rem;
  }
}
@media (min-width: 1650px) {
  .home-page .accordion .card-body .col-12 > div p {
    font-size: 1.32rem;
  }
}
.home-page .accordion .card-body .col-12 > div p:not(:last-child) {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-bottom: 0.5rem;
}
.home-page .nph-blue-bg a {
  color: #fcf7ff;
  text-decoration: underline;
}
.home-page .nph-blue-bg a:active, .home-page .nph-blue-bg a:hover, .home-page .nph-blue-bg a:focus {
  color: #fff;
  text-decoration: none;
}
.home-page hr.dotted-orange {
  border-top: 10px dotted #e57200;
  margin-bottom: 2rem;
  margin-left: -10px;
}

.nph-blue {
  color: #20558a !important;
}

.nph-blue-bg {
  background-color: #20558a !important;
}

.nph-orange-bg {
  background-color: #e57200 !important;
}

.nph-green-20 {
  background-color: rgba(113, 149, 0, 0.1254901961) !important;
}

@media (min-width: 1650px) {
  .col-xxl {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .col-xxl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
}
.fs-16 {
  font-size: clamp(1rem, 0.93334rem + 0.3333vw, 1.3333rem) !important;
}

.fs-19 {
  font-size: clamp(1rem, 0.8833333375rem + 0.5833333125vw, 1.5833333125rem) !important;
}

.fs-22 {
  font-size: clamp(1rem, 0.833333375rem + 0.833333125vw, 1.833333125rem) !important;
}

.fs-25 {
  font-size: clamp(1rem, 0.783375rem + 1.083125vw, 2.083125rem) !important;
}

.fs-38 {
  font-size: clamp(1rem, 0.56666675rem + 2.16666625vw, 3.16666625rem) !important;
}

.fs-50 {
  font-size: clamp(1rem, 0.3666675rem + 3.1666625vw, 4.1666625rem) !important;
}